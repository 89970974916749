import {Model} from "../vuemc";

export const METHOD_DEVICE  = "device";
export const METHOD_EMAIL   = "email";

export default class Twofacheck extends Model
{
    constructor(verification_method = METHOD_DEVICE, code_verification = null, sso_signup_key = null) {
        super();

        this.verification_method = verification_method;
        this.code_verification = code_verification;
        this.sso_signup_key = sso_signup_key;
    }

    defaults() {
        return {
            verification_method: null,
            code_verification: null,
            sso_signup_key: null,
        }
    }
}
